<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="container mt-4">
        <t-alert variant="info" class="my-2" :show="mailSent">
          {{ $t('mail_tool_mail_sent', { receipientCount }) }}
        </t-alert>
        <form class="m-4 md:mx-24" @submit="onSubmit">
          <div class="flex flex-col mb-4">
            <label class="w-24 font-semibold">{{ $t('mail_tool_to') }}</label>
            <t-select
              class="flex-grow"
              v-model="form.to"
              :options="jobOptions"
              value-attribute="id"
              text-attribute="name"
            ></t-select>
          </div>
          <div class="flex flex-col mb-4">
            <label class="w-24 font-semibold">{{ $t('mail_tool_message') }}</label>
            <t-select
              class="flex-grow"
              v-model="form.message"
              :options="messages"
              value-attribute="id"
              text-attribute="name"
            ></t-select>
          </div>
          <template v-if="isCustom">
            <div class="flex flex-col mb-4">
              <label class="w-24 font-semibold">{{ $t('mail_tool_custom_subject') }}</label>
              <t-input
                class="flex-grow"
                v-model="form.custom_subject"
              ></t-input>
            </div>
            <div class="flex flex-col mb-4">
              <label class="w-24 font-semibold">{{ $t('mail_tool_custom_body') }}</label>
              <t-textarea
                class="flex-grow"
                :rows="10"
                v-model="form.custom_body"
              ></t-textarea>
            </div>
          </template>
          <div>
            <t-button class="flex-grow" variant="primary" :disabled="loading">{{
              $t("send")
            }}</t-button>
          </div>
        </form>
      </div>
    </template>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { actions, getters } from "@/constants/state";
import Layout from "@/components/Layout.vue";
import i18n from "@/i18n";
import { sendMail } from "@/services/mailtool-service";
import { get } from "lodash";

export default {
  components: { Layout },
  data: () => ({
    loading: false,
    mailSent: false,
    receipientCount: 0,
    messages: [
      {
        id: 1,
        name: i18n.t("mail_tool_message_option1")
      },
      {
        id: 2,
        name: i18n.t("mail_tool_message_option2")
      },
      {
        id: 3,
        name: i18n.t("mail_tool_message_option3")
      }
    ],
    form: {
      to: 'all',
      message: 1,
      custom_subject: null,
      custom_body: null,
    },
  }),
  async mounted() {
    this.loading = true;
    try {
      await this.getJobs();
    } catch (error) {
      console.error("DEBUG: Mailtool Form", error);
    }
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getJobs: actions.JOBS_FETCH_ACTION,
    }),
    async onSubmit(e) {
      e.preventDefault();
      this.mailSent = false;
      this.loading = true;
      try {
        const { data } = await sendMail(this.form.to, this.form.message,
          this.form.custom_subject, this.form.custom_body);
        this.receipientCount = get(data, 'receipient_count');
        this.mailSent = true;
      } catch (error) {
        console.error("DEBUG: onSubmit", error);
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      jobs: getters.JOBS_GETTER
    }),
    isCustom() {
      return this.form.message == 3;
    },
    jobOptions() {
      return [
        {
          id: 'all',
          name: i18n.t("mail_tool_jobs_all"),
        },
        ...this.jobs
      ];
    }
  }
};
</script>
